/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.face.light};
  border-radius: 31px;
  display: inline-block;
  
  & > a, & > button {
    text-shadow: none;
    color: ${props => props.theme.color.text.main};
    font-size: ${props => props.theme.font.size['18']};
    font-weight: ${props => props.theme.font.weight.m};
    padding: 10px 30px;
    
    width: 100%;
    height: 100%;
    display: block;

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.color.face.main};
    }
  }
`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault