/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.face.main};
  border-radius: 31px;
  display: inline-block;

  ${props => props.form && css`
    background-color: ${props.theme.color.text.light};
    width: 200px;
    border-radius: 31px;
    float: right;

    & > a, & > button {
      color: ${props.theme.color.text.main} !important;  
    }

  `}
  
  & > a, & > button {
    text-shadow: none;
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size['18']};
    font-weight: ${props => props.theme.font.weight.m};
    padding: 10px 30px;
    
    width: 100%;
    height: 100%;
    display: block;

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.color.face.light};
    }
  }
`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className, form }) => (
  <StyledButton className={className} form={form}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault