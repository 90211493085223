import React, { createContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

export const BlogContext = createContext({})

export const BlogProvider = BlogContext.Provider
export const BlogConsumer = BlogContext.Consumer

export default function Blogik({ settings: options, children }) {
  const settings = { ...{
    id: 'blog',
    postType: 'posts',
    postIds: [],
    limit: 6,
    usePagination: false,
    outsideFilter: null,
   }, ...options }

  const {
    posts: { nodes: postsEdges },
    diensten: { nodes: dienstenEdges },
    referenties: { nodes: referentiesEdges },
    vacatures: { nodes: vacaturesEdges }
  } = useStaticQuery(graphql`
    {
      posts: allWordpressPost(sort: {order: ASC, fields: date}) {
        nodes {
          ...postsFragment
        }
      }

      diensten: allWordpressPage(filter: {wordpress_parent: {eq: 175}}, sort: {order: ASC, fields: date}) {
        nodes {
          title
          path
          slug
          wordpress_id

          acf {
            preview {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              description
              business_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }

      referenties: allWordpressWpReferenties(sort: {order: ASC, fields: date}) {
        nodes {
          title
          path
          slug
          wordpress_id

          acf {
            preview {
              type
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              description
            }
          }
        }
      }

      vacatures: allWordpressWpVacatures(sort: {order: ASC, fields: date}) {
        nodes {
          title
          path
          slug
          wordpress_id

          acf {
            preview {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              description
            }
          }
        }
      }
    }
  `)

  const edgeLiterals = {
    'posts': postsEdges,
    'diensten': dienstenEdges,
    'referenties': referentiesEdges,
    'vacatures': vacaturesEdges,
    'default': postsEdges
  }

  const edges = edgeLiterals[settings.postType] || edgeLiterals.default

  const [selectedFilters, setSelectedFilters] = useState([])
  const [offset, setOffset] = useState(1)

  const setSelectedFiltersLocal = filter => {
    const newSelectedFilters = [ ...selectedFilters ]

    const filterIndex = newSelectedFilters.indexOf(filter)

    if(filterIndex === -1) {
      newSelectedFilters.push(filter)
    } else {
      newSelectedFilters.splice(filterIndex, 1)
    }

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(`${settings.id}-filters`, JSON.stringify(newSelectedFilters))
    }

    setSelectedFilters(newSelectedFilters)
  }

  const setOffsetLocal = newOffset => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(`${settings.id}-offset`, newOffset)
    }

    setOffset(newOffset)
  }

  const incrementOffset = () => {
    let newOffset = parseFloat(offset)

    newOffset += 1

    setOffsetLocal(newOffset)
  }

  const decrementOffset = () => {
    let newOffset = parseFloat(offset)

    newOffset -= 1

    setOffsetLocal(newOffset)
  }

  const isSelected = filter => selectedFilters.indexOf(filter) !== -1

  const filterPosts = (posts, { ids }) => posts.filter((node, index) => {
    let response = true

    if(settings.limit !== null && (((index + 1) > (offset * settings.limit)) || (settings.usePagination && offset > (index + 1)))) {
      return false
    }

    if(ids.length > 0 && ids.indexOf(node.wordpress_id) === -1) {
      return false
    }

    if (settings.outsideFilter !== null) {
      if(settings.outsideFilter !== node.acf.preview.type) {
        return false
      }
    }

    if(selectedFilters.length > 0) {
      response = false

      node.categories.forEach(category => {
        if(selectedFilters.indexOf(category.wordpress_id) !== -1) {
          response = true
        }
      })
    }

    return response
  })

  useEffect(() => {
    if(typeof localStorage !== 'undefined') {
      const storageSelectedFilters = localStorage.getItem(`${settings.id}-filters`)
      const storageOffset = localStorage.getItem(`${settings.id}-offset`)

      if (storageSelectedFilters) {
        setSelectedFilters(JSON.parse(storageSelectedFilters))
      }

      if (storageOffset) {
        setOffset(storageOffset)
      }
    }
  }, [])

  const posts = filterPosts(edges, {
    ids: settings.postIds
  })

  return (
    <BlogProvider
      value={{
        setSelectedFilters: setSelectedFiltersLocal,
        selectedFilters,
        isSelected,
        setOffset: setOffsetLocal,
        incrementOffset,
        decrementOffset,
        offset,
        limit: settings.limit,
        filterPosts,
        unfilteredPosts: edges,
        posts,
        hasPosts: posts.length > 0,
        showMoreButton: edges.length > posts.length && (offset * settings.limit) < edges.length,
        showLessButton: offset > 1,
      }}
    >
      {children}
    </BlogProvider>
  )
}

const Button = styled.div`
  outline: none;

  &:focus {
    outline: none;
  }
`

export const BlogFilter = ({ className, children, id }) => {
  return (
    <BlogConsumer>
      {(context) => {
        return (
          <Button
            className={className}
            role="button"
            data-active={context.isSelected(id) ? 1 : 0}
            onClick={() => {
              context.setSelectedFilters(id)
            }}
            onKeyPress={() => { return null }}
            tabIndex={0}
          >
            {children}
          </Button>
        )
      }}
    </BlogConsumer>
  )
}

export const BlogButton = ({ className, children, increment = true }) => {
  return (
    <BlogConsumer>
      {(context) => {
        return (
          <Button
            className={className}
            role="button"
            onClick={() => {
              if(increment) {
                context.incrementOffset()
              } else {
                context.decrementOffset()
              }
            }}
            onKeyPress={() => { return null }}
            tabIndex={0}
          >
            {children}
          </Button>
        )
      }}
    </BlogConsumer>
  )
}

export const BlogPagination = ({ className }) => {
  return (
    <BlogConsumer>
      {(context) => {
        const paginations = context.unfilteredPosts.length / context.limit
        const pages = []

        for(let i = 0; i < paginations; i += 1) {
          pages.push(i + 1)
        }

        return (
          <div
            className={className}
          >
            {pages.map(page => (
              <Button
                key={page}
                role="button"
                data-active={parseFloat(page) === parseFloat(context.offset) ? 1 : 0}
                onClick={() => {
                  context.setOffset(page)
                }}
                onKeyPress={() => { return null }}
                tabIndex={0}
              >
                {page}
              </Button>
            ))}
          </div>
        )
      }}
    </BlogConsumer>
  )
}
