/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import AltLogo from 'img/logo_alt.inline.svg'
import Open from 'img/menu.inline.svg'
import OpenMain from 'img/menu_main.inline.svg'
import Close from 'img/close.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

const StyledHeader = styled.nav`
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100px;
  z-index: 2;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 50px;
    width: auto;
  }
`

const NavButton = styled.button`
  width: 45px;
  height: 45px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const Header = ({ altLogo, setIsScrollable }) => {
  const {
    fields
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 97}) {
        acf {
          menu {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <Container className="container">
        <div className="row w-100">
          <div className="col-6">
            <Brand to="/">
              {altLogo ? (
                <AltLogo />
              ) : (
                <Logo />
              )}
            </Brand>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center pr-0">
            <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />
            <MenuDesktop fields={fields} />
          </div>
        </div>
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${props => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  @media screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    padding: 0 0 100px 0;
  }

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const MenuMobile = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <Open className="d-flex d-md-none" />
        <OpenMain className="d-md-flex d-none"  />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <Close />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const MenuDesktop = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex justify-content-end">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  padding-left: 40px;

  & > a {
    color: ${props => props.theme.color.text.main};
    font-size: ${props => props.theme.font.size.xm};

    @media screen and (max-width: 991px) {
      color: ${props => props.theme.color.text.light};
      font-size: ${props => props.theme.font.size.xl};
      text-align: left;
      text-transform: uppercase;
    }

    &[aria-current] {

    }

    &:hover {

    }
  }
`

const Menu = ({ fields }) => (
  <>
    {fields.acf.menu.map(({ link: { url, title } }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Item key={index}>
        <CustomLink to={url}>{title}</CustomLink>
      </Item>
    ))}
  </>
)

export default Header
