/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

import Slider from 'react-slick'

// Images
import LinkedIn from 'img/linkedin.inline.svg'
import Facebook from 'img/facebook.inline.svg'

import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

const StyledFooter = styled.div`
  background: ${props => props.theme.color.text.secondary};
  z-index: 0;

  @media (min-width: 992px){
    min-height: 650px;
    margin-top: -18rem;
    padding-top: 7.5rem;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const ImageBackground = styled(Image)`
  height: 100%;
  width: 100%;
  opacity: 0.45;
`

const MenuTitle = styled.h2`
  font-size: ${props => props.theme.font.size.xm};
  font-weight: ${props => props.theme.font.weight.m};
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
`

const Stripe = styled.div`
  width: 63px;
  height: 1px;
  background-color: ${props => props.theme.color.face.contrast};
`

const MenuList = styled.ul`
  list-style: none;
  margin-left: -2.4rem;
  margin-bottom: 0;
`

const Item = styled.li`
  font-size: ${props => props.theme.font.size.xm};
  line-height: 35px;

  & a {
    color: ${props => props.theme.color.text.light};
  }
`

const Content = styled(ParseContent)`
  font-size: ${props => props.theme.font.size.xm};
  line-height: 35px;
  color: ${props => props.theme.color.text.light};
`

const StyledSlider = styled(Slider)``

const StyledImage = styled(Image)`
  object-fit: contain !important;
  width: 100%;
  height: auto;
`

const CertImage = styled(Image)`
  max-width: 90px;
`

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 20px;
`

const Footer = () => {
  const {
    fields
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 98}) {
        acf {
          background {
            image {
              localFile {
                publicURL
              }
            }
          }
          menu {
            link {
              title
              url
            }
          }
          services {
            link {
              title
              url
            }
          }
          contact {
            contactdata
          }
          certificates {
            certificate {
              localFile {
                publicURL
              }
            }
          }
          social {
            facebook
            linkedin
          }
          partners {
            logo {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    accessibility: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
    ]
  }

  return (
    <StyledFooter className="position-relative d-flex align-items-center justify-content-center">
      <Background>
        <ImageBackground src={fields.acf.background.image} />
      </Background>
      <div className="container py-5">
        <div className="row py-5 flex-column flex-md-row">
          <div className="col-lg col-md-4 mb-5 mb-lg-0 d-flex flex-column align-items-center text-center">
            <MenuTitle className="mb-4">Menu</MenuTitle>
            <Stripe className="mx-2" />
            <MenuList className="py-4">
              {fields.acf.menu.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Item key={index}>
                  <Link to={url}>
                    {title}
                  </Link>
                </Item>
              ))}
            </MenuList>
            <Stripe />
          </div>
          <div className="col-lg col-md-4 mb-5 mb-lg-0 d-flex flex-column align-items-center text-center">
            <MenuTitle className="mb-4">Diensten</MenuTitle>
            <Stripe className="mx-2" />
            <MenuList className="py-4">
              {fields.acf.services.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Item key={index}>
                  <Link to={url}>
                    {title}
                  </Link>
                </Item>
              ))}
            </MenuList>
            <Stripe />
          </div>
          <div className="col-lg col-md-4 mb-5 mb-lg-0 d-flex flex-column align-items-center text-center">
            <MenuTitle className="mb-4">Contact</MenuTitle>
            <Stripe className="mx-2" />
            <Content className="py-4" content={fields.acf.contact.contactdata} />
            <Stripe />
          </div>
          <div className="col-lg col-md-4 mb-5 mb-lg-0 d-flex flex-column align-items-center text-center">
            <MenuTitle className="mb-4">Certificaten</MenuTitle>
            <Stripe className="mx-2" />
            <div className="py-4">
              {fields.acf.certificates.map(({ certificate }, index)=> (
                <div className="mb-2" key={index}>
                  <CertImage src={certificate} />
                </div>
              ))}
            </div>
            <Stripe />
          </div>
          <div className="col-lg col-md-4 mb-5 mb-lg-0 d-flex flex-column align-items-center text-center">
            <a href={fields.acf.social.linkedin} className="mb-3" target="_blank"> 
              <LinkedIn />
            </a>
            <a href={fields.acf.social.facebook} className="mb-3" target="_blank"> 
              <Facebook />
            </a>
          </div>
        </div>
        {/* <div className="row pt-5 align-items-center justify-content-between"> */}
        <StyledSlider {...settings}>
          {fields.acf.partners.map(({ logo }, index) => (
            <div>
              <ImageContainer key={index}>
                <StyledImage src={logo} />
              </ImageContainer>
            </div>
          ))}
        </StyledSlider>
      </div>
      {/* </div> */}
    </StyledFooter>
  )
}

export default Footer
